

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import store from '@/store'

import Label from '@/components/atoms/Label.vue'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'
import { l, localeFromPath, truncateText } from '@/utils/helpers'
import SvgIcon from '@/components/atoms/SvgIcon.vue'


/* The content displayed in this card can be
 * 1. passed as an object by the parent component
 * 2. fetched from the API if a UUID is passed from the parent and no cardObject is present
 */
@Component({
  components: {
    ResponsiveImage,
    Label,
    SvgIcon,
  },
  data: () => ({
    card: {},
  }),
  methods: {
    l,
  },
})
export default class Card extends Vue {
  @Prop() private cardObject!: Object

  @Prop() private uuid!: string

  @Prop({ default: 1 }) private size!: number

  private card!: any

  @Watch('cardObject')
  onCardObjectChange(newCardObject: any, oldCardObject: any) {
    this.card = newCardObject
  }

  @Watch('uuid')
  onUuidChange(newUuid: string, oldUuid: string) {
    this.card = this.$store.getters.curated[newUuid]
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.cardObject) this.card = this.cardObject

    // Fetch from the API if a UUID is passed as props and if no cardObject prop is present
    if (this.uuid && !this.cardObject) {
      store.dispatch(
        'fetch',
        `/?uuid=${this.uuid}`,
      )
        .then((result: any) => {
          if (result) {
            // Set the content in the VUEX store with its uuid as key
            try {
              store.dispatch('set', { key: `active.curated.${this.uuid}`, data: result })
              // Update render
              this.$nextTick(() => {
                this.card = this.$store.getters.curated[this.uuid]
              })
            } catch (err) {
              // console.log(err)
            }
          }
        })
        .catch(
          (error: string) => { throw new Error(error) },
        )
    }
  }

  getAspectRatioClass(size: number) {
    if (size === 2) {
      return 'aspect-ratio--1x1-l'
    }
    return ''
  }

  getMainClasses(size: number) {
    if (size === 2) {
      return 'vertical-center'
    }
    return ''
  }

  getCardWrapperClasses(size: number) {
    return size === 2 ? 'card__img--high' : 'card__img--square'
  }

  truncateCardText(text:string, size:number) {
    return truncateText(text, size)
  }

  get locale() {
    return localeFromPath(this.$route.path)
  }
}
