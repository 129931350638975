

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import { orderPostsByDate } from '@/utils/helpers'
import Card from '@/components/atoms/Card.vue'
import WorkshopCard from '@/components/atoms/WorkshopCard.vue'
import SectionTitle from '@/components/atoms/SectionTitle.vue'

import Loader from '@/components/atoms/Loader.vue'
import store from '@/store'


@Component({
  components: {
    Card,
    WorkshopCard,
    SectionTitle,
    Loader,
  },
})

export default class ContentSection extends Vue {
  @Prop() private titleLabel!: string

  @Prop() private titleLink!: string

  @Prop({ type: Array, default: () => undefined }) private posts!: Array<any>

  @Prop({ type: Array, default: () => undefined }) private uuids!: Array<any>

  private educateFeatures: Array<{}>

  constructor() {
    super()
    this.educateFeatures = []
  }

  created() {
    if (this.checkIfEducateHome()) {
      try {
        Promise.all([
          store.dispatch(
            'fetch',
            '/?slug=feature-guides',
          ),
          store.dispatch(
            'fetch',
            '/?slug=feature-workshops',
          ),
        ]).then((res) => {
          this.educateFeatures.push(...res[0], ...res[1])
        })
      } catch (err) {
        throw new Error(err)
      }
    }
  }

  get sortedPosts() {
    if (this.posts) {
      return orderPostsByDate(this.posts, 'desc')
    }
    return undefined
  }

  getCardsWrapperClass(contentType: any) {
    if (contentType === 'workshop') {
      return 'w-100 relative flex flex-column'
    }
    return 'content__inner flex flex-wrap justify-between'
  }

  getCardsInnerWrapperClass(contentType: any) {
    if (contentType === 'workshop') {
      return 'w-100 workshop'
    }
    return 'content__small relative content-section__item'
  }

  checkIfEducateHome() {
    const routeArr = this.$route.fullPath.split('/').filter(r => r.length)
    return routeArr[routeArr.length - 1] === 'learn'
  }
}
