

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import arrow from '@/assets/svg/arrow.svg'

@Component({
  components: {
    arrow,
  },
})
export default class SectionTitle extends Vue {
  @Prop() private label!: string

  @Prop() private link!: string
}
